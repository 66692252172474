.editor-list .box-model-wrapper {
  width: calc(100% - 76px);
  height: 60px;
  margin: 20px auto;
  border: 1px solid rgba(255, 255, 255, 0.55);
}
.editor-list .box-model {
  position: relative;
  height: 100%;
}
.editor-list .box-model .top,
.editor-list .box-model .right,
.editor-list .box-model .bottom,
.editor-list .box-model .left,
.editor-list .box-model .center,
.editor-list .box-model .top-left,
.editor-list .box-model .top-right,
.editor-list .box-model .bottom-right,
.editor-list .box-model .bottom-left {
  position: absolute;
  margin: auto;
  width: 76px;
  height: 22px;
}
.editor-list .box-model .top .ant-select-selection,
.editor-list .box-model .right .ant-select-selection,
.editor-list .box-model .bottom .ant-select-selection,
.editor-list .box-model .left .ant-select-selection,
.editor-list .box-model .center .ant-select-selection,
.editor-list .box-model .top-left .ant-select-selection,
.editor-list .box-model .top-right .ant-select-selection,
.editor-list .box-model .bottom-right .ant-select-selection,
.editor-list .box-model .bottom-left .ant-select-selection {
  background: #262626;
}
.editor-list .box-model .top .ant-input,
.editor-list .box-model .right .ant-input,
.editor-list .box-model .bottom .ant-input,
.editor-list .box-model .left .ant-input,
.editor-list .box-model .center .ant-input,
.editor-list .box-model .top-left .ant-input,
.editor-list .box-model .top-right .ant-input,
.editor-list .box-model .bottom-right .ant-input,
.editor-list .box-model .bottom-left .ant-input,
.editor-list .box-model .top .ant-select-selection__placeholder,
.editor-list .box-model .right .ant-select-selection__placeholder,
.editor-list .box-model .bottom .ant-select-selection__placeholder,
.editor-list .box-model .left .ant-select-selection__placeholder,
.editor-list .box-model .center .ant-select-selection__placeholder,
.editor-list .box-model .top-left .ant-select-selection__placeholder,
.editor-list .box-model .top-right .ant-select-selection__placeholder,
.editor-list .box-model .bottom-right .ant-select-selection__placeholder,
.editor-list .box-model .bottom-left .ant-select-selection__placeholder {
  text-align: center;
}
.editor-list .box-model .top {
  top: -11px;
  left: 0;
  right: 0;
}
.editor-list .box-model .top .editor-color-picker {
  left: -85px;
}
.editor-list .box-model .right {
  right: -38px;
  top: 0;
  bottom: 0;
}
.editor-list .box-model .right .editor-color-picker {
  right: -13px;
  left: auto;
}
.editor-list .box-model .bottom {
  bottom: -11px;
  left: 0;
  right: 0;
}
.editor-list .box-model .left {
  left: -38px;
  top: 0;
  bottom: 0;
}
.editor-list .box-model .center {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.editor-list .box-model .top-left {
  top: -11px;
  left: -38px;
}
.editor-list .box-model .top-right {
  top: -11px;
  right: -38px;
}
.editor-list .box-model .bottom-left {
  bottom: -11px;
  left: -38px;
}
.editor-list .box-model .bottom-right {
  bottom: -11px;
  right: -38px;
}
