.editor-color {
  width: 100%;
  height: 22px;
  border-radius: 4px;
  cursor: pointer;
  overflow: hidden;
  display: block;
  transition: box-shadow 0.3s, border 0.3s;
  border: 1px solid #A4A7A8;
}
.editor-color i {
  width: 100%;
  height: 100%;
  display: block;
  position: relative;
}
.editor-color i:after {
  content: '';
  display: block;
  position: absolute;
  width: 0;
  height: 0;
  border-bottom: 4px solid white;
  border-left: 4px solid transparent;
  right: 1px;
  bottom: 1px;
}
.editor-color.active {
  border-color: rgba(255, 255, 255, 0.9);
  box-shadow: 0 0 4px rgba(255, 255, 255, 0.9);
}
.editor-color-wrapper:hover .color-close {
  opacity: 1;
}
.editor-color-wrapper .color-close {
  position: absolute;
  line-height: 12px;
  text-align: center;
  width: 12px;
  height: 12px;
  top: -5px;
  left: -5px;
  border-radius: 100%;
  background: #ff0000;
  color: #fff;
  opacity: 0;
  cursor: pointer;
  transition: opacity 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
}
.editor-color-wrapper .color-close .anticon-close {
  font-size: 12px;
  transform: scale(0.8);
}
