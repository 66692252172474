.ant-collapse.editor-list {
  background-color: #262626;
  border-radius: 0;
}
.ant-collapse.editor-list > .ant-collapse-item {
  border-top: 1px solid #000;
  border-bottom: none;
}
.ant-collapse.editor-list > .ant-collapse-item:first-child {
  border-top: none;
}
.ant-collapse.editor-list > .ant-collapse-item > .ant-collapse-header {
  height: 32px;
  line-height: 32px;
  text-indent: 10px;
  font-size: 14px;
  color: #fff;
  padding: 0 0 0 12px;
  cursor: pointer;
  position: relative;
  font-weight: normal;
  border-bottom: 1px solid transparent;
  transition: border 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.ant-collapse.editor-list > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow {
  display: inline-block;
  position: absolute;
  right: 20px;
  top: 50%;
  left: auto;
  transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.ant-collapse.editor-list > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow > svg {
  display: none;
}
.ant-collapse.editor-list > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow:after,
.ant-collapse.editor-list > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow:before {
  content: '';
  display: block;
  position: absolute;
  width: 6px;
  height: 2px;
  background: #fff;
  transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.ant-collapse.editor-list > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow:after {
  transform: rotate(45deg) translateX(-2px);
}
.ant-collapse.editor-list > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow:before {
  transform: rotate(-45deg) translateX(2px);
}
.ant-collapse.editor-list > .ant-collapse-item-disabled > .ant-collapse-header {
  cursor: not-allowed;
  color: #999;
  background-color: #f3f3f3;
}
.ant-collapse.editor-list .ant-collapse-content {
  color: rgba(255, 255, 255, 0.55);
  padding: 1px 16px;
  background-color: #262626;
}
.ant-collapse.editor-list .ant-collapse-content > .ant-collapse-content-box {
  margin-top: 16px;
  margin-bottom: 16px;
  padding: 0;
}
.ant-collapse.editor-list .ant-collapse-content-inactive {
  display: none;
}
.ant-collapse.editor-list > .ant-collapse-item-active > .ant-collapse-header {
  border-bottom-color: #000;
}
.ant-collapse.editor-list > .ant-collapse-item-active > .ant-collapse-header .ant-collapse-arrow {
  transform: translateY(-3px);
}
.ant-collapse.editor-list > .ant-collapse-item-active > .ant-collapse-header .ant-collapse-arrow:after {
  transform: rotate(-45deg) translateX(-2px);
}
.ant-collapse.editor-list > .ant-collapse-item-active > .ant-collapse-header .ant-collapse-arrow:before {
  transform: rotate(45deg) translateX(2px);
}
