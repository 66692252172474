.Resizer {
  background: #000;
  opacity: 0.2;
  z-index: 1;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -moz-background-clip: padding;
  -webkit-background-clip: padding;
  background-clip: padding-box;
}
.Resizer:hover {
  -webkit-transition: all 2s ease;
  transition: all 2s ease;
}
.Resizer.horizontal {
  height: 11px;
  margin: -5px 0;
  border-top: 5px solid rgba(255, 255, 255, 0);
  border-bottom: 5px solid rgba(255, 255, 255, 0);
  cursor: row-resize;
  width: 100%;
}
.Resizer.horizontal:hover {
  border-top: 5px solid rgba(0, 0, 0, 0.5);
  border-bottom: 5px solid rgba(0, 0, 0, 0.5);
}
.Resizer.vertical {
  width: 11px;
  margin: 0 -5px;
  border-left: 5px solid rgba(255, 255, 255, 0);
  border-right: 5px solid rgba(255, 255, 255, 0);
  cursor: col-resize;
}
.Resizer.vertical:hover {
  border-left: 5px solid rgba(0, 0, 0, 0.5);
  border-right: 5px solid rgba(0, 0, 0, 0.5);
}
.Resizer.disabled {
  cursor: not-allowed;
}
.Resizer.disabled:hover {
  border-color: transparent;
}
.render-interactive-mask {
  position: relative;
}
.render-interactive-mask::after {
  content: " ";
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}
.saucer-editor-previewer .render-interactive-mask::after {
  content: none;
}
.saucer-template-menu {
  overflow: auto;
}
.saucer-template-menu .saucer-template-menu__item {
  padding: 10px;
  cursor: grab;
}
.saucer-template-menu .saucer-template-menu__item:hover {
  background-color: #ccc;
}
.saucer-editor-viewport {
  height: 100%;
  width: 100%;
  outline: 0;
}
.saucer-editor-viewport .saucer-drop-indicator {
  padding: 20px;
  border: 3px dashed #999;
  background-color: #ccc;
  border-radius: 3px;
  text-align: center;
}
.saucer-editor-viewport > .saucer-render-wrapper {
  width: 100%;
  height: 100%;
}
.saucer-editor-viewport .saucer-render-wrapper {
  position: relative;
  cursor: grab;
}
.saucer-editor-viewport .saucer-render-wrapper::before {
  content: none;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  border: 1px dashed #ccc;
}
.saucer-editor-viewport .saucer-render-wrapper:hover::before,
.saucer-editor-viewport .saucer-render-wrapper.saucer-render-wrapper__hover::before {
  content: ' ';
}
.saucer-editor-viewport .saucer-render-wrapper.saucer-render-wrapper__selected::before {
  content: ' ';
  border: 1px solid #4285f4;
}
.saucer-editor-viewport .saucer-render-wrapper::after {
  content: none;
  position: absolute;
  left: 0;
  right: 0;
  border: 1px solid #4285f4;
}
.saucer-editor-viewport .saucer-render-wrapper.saucer-render-wrapper__hover-top::after {
  content: ' ';
  top: 0;
}
.saucer-editor-viewport .saucer-render-wrapper.saucer-render-wrapper__hover-bottom::after {
  content: ' ';
  bottom: 0;
}
.saucer-editor-viewport .saucer-render-wrapper > .saucer-render-wrapper__tools {
  position: absolute;
  right: 0;
  top: 0;
  background-color: #4285f4;
  color: white;
  border-bottom-left-radius: 3px;
  font-size: 12px;
  display: flex;
}
.saucer-editor-viewport .saucer-render-wrapper > .saucer-render-wrapper__tools path {
  fill: currentColor;
}
.saucer-editor-viewport .saucer-render-wrapper > .saucer-render-wrapper__tools .saucer-render-wrapper__tools-item {
  cursor: pointer;
  padding: 0 2px;
  display: flex;
  align-items: center;
}
.saucer-editor-viewport .saucer-render-wrapper > .saucer-render-wrapper__tools .saucer-render-wrapper__tools-item + .saucer-render-wrapper__tools-item {
  border-left: 1px solid white;
}
.saucer-editor-treeview {
  overflow: auto;
  width: 100%;
}
.saucer-editor-inspector {
  overflow: auto;
  width: 100%;
}
