.editor-list,
.editor-list-popover .ant-popover-inner {
  line-height: 22px;
  font-size: 12px;
}
.editor-list .icon,
.editor-list-popover .ant-popover-inner .icon {
  font-size: 16px;
  height: 22px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-pack: center;
      justify-content: center;
}
.editor-list .ant-tag,
.editor-list-popover .ant-popover-inner .ant-tag {
  cursor: pointer;
}
.editor-list-dropdown,
.editor-list-popover .ant-popover-inner-dropdown {
  font-size: 12px;
}
.editor-list-dropdown .ant-dropdown-menu-item,
.editor-list-popover .ant-popover-inner-dropdown .ant-dropdown-menu-item {
  font-size: 12px;
}
.editor-list .ant-radio-group-small .ant-radio-button-wrapper,
.editor-list-popover .ant-popover-inner .ant-radio-group-small .ant-radio-button-wrapper {
  padding: 0 8px;
}
.editor-list .ant-row,
.editor-list-popover .ant-popover-inner .ant-row {
  margin-bottom: 8px;
}
.editor-list .ant-collapse-content-box,
.editor-list-popover .ant-popover-inner .ant-collapse-content-box {
  position: relative;
}
.editor-list .ant-input-number,
.editor-list-popover .ant-popover-inner .ant-input-number,
.editor-list .ant-input,
.editor-list-popover .ant-popover-inner .ant-input {
  width: 100%;
  background: #262626;
  border-color: rgba(255, 255, 255, 0.55);
  color: rgba(255, 255, 255, 0.55);
}
.editor-list .ant-input-number.ant-input-disabled,
.editor-list-popover .ant-popover-inner .ant-input-number.ant-input-disabled,
.editor-list .ant-input.ant-input-disabled,
.editor-list-popover .ant-popover-inner .ant-input.ant-input-disabled {
  color: rgba(255, 255, 255, 0.1);
  border-color: rgba(255, 255, 255, 0.1);
}
.editor-list .ant-input-number input,
.editor-list-popover .ant-popover-inner .ant-input-number input,
.editor-list .ant-input input,
.editor-list-popover .ant-popover-inner .ant-input input {
  background: #262626 !important;
  color: rgba(255, 255, 255, 0.55);
  border-color: rgba(255, 255, 255, 0.55);
}
.editor-list .ant-input-number:hover,
.editor-list-popover .ant-popover-inner .ant-input-number:hover,
.editor-list .ant-input:hover,
.editor-list-popover .ant-popover-inner .ant-input:hover,
.editor-list .ant-input-number:focus,
.editor-list-popover .ant-popover-inner .ant-input-number:focus,
.editor-list .ant-input:focus,
.editor-list-popover .ant-popover-inner .ant-input:focus,
.editor-list .ant-input-number-focused,
.editor-list-popover .ant-popover-inner .ant-input-number-focused,
.editor-list .ant-input-focused,
.editor-list-popover .ant-popover-inner .ant-input-focused {
  border-color: rgba(255, 255, 255, 0.9);
  box-shadow: 0 0 4px rgba(255, 255, 255, 0.9);
}
.editor-list .ant-input-number::-moz-placeholder,
.editor-list-popover .ant-popover-inner .ant-input-number::-moz-placeholder,
.editor-list .ant-input::-moz-placeholder,
.editor-list-popover .ant-popover-inner .ant-input::-moz-placeholder {
  color: rgba(255, 255, 255, 0.15);
  opacity: 1;
}
.editor-list .ant-input-number:-ms-input-placeholder,
.editor-list-popover .ant-popover-inner .ant-input-number:-ms-input-placeholder,
.editor-list .ant-input:-ms-input-placeholder,
.editor-list-popover .ant-popover-inner .ant-input:-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.15);
}
.editor-list .ant-input-number::-webkit-input-placeholder,
.editor-list-popover .ant-popover-inner .ant-input-number::-webkit-input-placeholder,
.editor-list .ant-input::-webkit-input-placeholder,
.editor-list-popover .ant-popover-inner .ant-input::-webkit-input-placeholder {
  color: rgba(255, 255, 255, 0.15);
}
.editor-list .ant-input-group-addon,
.editor-list-popover .ant-popover-inner .ant-input-group-addon {
  padding: 0 4px;
  color: rgba(255, 255, 255, 0.55);
  border-color: rgba(255, 255, 255, 0.55);
  background-color: #262626;
}
.editor-list .ant-select-single:not(.ant-select-customize-input) .ant-select-selector,
.editor-list-popover .ant-popover-inner .ant-select-single:not(.ant-select-customize-input) .ant-select-selector,
.editor-list .ant-select,
.editor-list-popover .ant-popover-inner .ant-select,
.editor-list .ant-select-selection,
.editor-list-popover .ant-popover-inner .ant-select-selection,
.editor-list .ant-select-auto-complete.ant-select,
.editor-list-popover .ant-popover-inner .ant-select-auto-complete.ant-select {
  background: #262626;
  color: rgba(255, 255, 255, 0.55);
  border-color: rgba(255, 255, 255, 0.55);
}
.editor-list .ant-select-single:not(.ant-select-customize-input) .ant-select-selector:hover,
.editor-list-popover .ant-popover-inner .ant-select-single:not(.ant-select-customize-input) .ant-select-selector:hover,
.editor-list .ant-select:hover,
.editor-list-popover .ant-popover-inner .ant-select:hover,
.editor-list .ant-select-selection:hover,
.editor-list-popover .ant-popover-inner .ant-select-selection:hover,
.editor-list .ant-select-auto-complete.ant-select:hover,
.editor-list-popover .ant-popover-inner .ant-select-auto-complete.ant-select:hover,
.editor-list .ant-select-single:not(.ant-select-customize-input) .ant-select-selector:focus,
.editor-list-popover .ant-popover-inner .ant-select-single:not(.ant-select-customize-input) .ant-select-selector:focus,
.editor-list .ant-select:focus,
.editor-list-popover .ant-popover-inner .ant-select:focus,
.editor-list .ant-select-selection:focus,
.editor-list-popover .ant-popover-inner .ant-select-selection:focus,
.editor-list .ant-select-auto-complete.ant-select:focus,
.editor-list-popover .ant-popover-inner .ant-select-auto-complete.ant-select:focus {
  border-color: rgba(255, 255, 255, 0.9);
}
.editor-list .ant-select-single:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-selected-value,
.editor-list-popover .ant-popover-inner .ant-select-single:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-selected-value,
.editor-list .ant-select .ant-select-selection-selected-value,
.editor-list-popover .ant-popover-inner .ant-select .ant-select-selection-selected-value,
.editor-list .ant-select-selection .ant-select-selection-selected-value,
.editor-list-popover .ant-popover-inner .ant-select-selection .ant-select-selection-selected-value,
.editor-list .ant-select-auto-complete.ant-select .ant-select-selection-selected-value,
.editor-list-popover .ant-popover-inner .ant-select-auto-complete.ant-select .ant-select-selection-selected-value {
  padding-right: 8px;
}
.editor-list .ant-select-single:not(.ant-select-customize-input) .ant-select-selector .ant-select-arrow,
.editor-list-popover .ant-popover-inner .ant-select-single:not(.ant-select-customize-input) .ant-select-selector .ant-select-arrow,
.editor-list .ant-select .ant-select-arrow,
.editor-list-popover .ant-popover-inner .ant-select .ant-select-arrow,
.editor-list .ant-select-selection .ant-select-arrow,
.editor-list-popover .ant-popover-inner .ant-select-selection .ant-select-arrow,
.editor-list .ant-select-auto-complete.ant-select .ant-select-arrow,
.editor-list-popover .ant-popover-inner .ant-select-auto-complete.ant-select .ant-select-arrow {
  right: 4px;
  color: rgba(255, 255, 255, 0.55);
}
.editor-list .ant-select-single:not(.ant-select-customize-input) .ant-select-selector input,
.editor-list-popover .ant-popover-inner .ant-select-single:not(.ant-select-customize-input) .ant-select-selector input,
.editor-list .ant-select input,
.editor-list-popover .ant-popover-inner .ant-select input,
.editor-list .ant-select-selection input,
.editor-list-popover .ant-popover-inner .ant-select-selection input,
.editor-list .ant-select-auto-complete.ant-select input,
.editor-list-popover .ant-popover-inner .ant-select-auto-complete.ant-select input {
  padding: 0 7px;
}
.editor-list .ant-select-single:not(.ant-select-customize-input) .ant-select-selector input:hover,
.editor-list-popover .ant-popover-inner .ant-select-single:not(.ant-select-customize-input) .ant-select-selector input:hover,
.editor-list .ant-select input:hover,
.editor-list-popover .ant-popover-inner .ant-select input:hover,
.editor-list .ant-select-selection input:hover,
.editor-list-popover .ant-popover-inner .ant-select-selection input:hover,
.editor-list .ant-select-auto-complete.ant-select input:hover,
.editor-list-popover .ant-popover-inner .ant-select-auto-complete.ant-select input:hover,
.editor-list .ant-select-single:not(.ant-select-customize-input) .ant-select-selector input:focus,
.editor-list-popover .ant-popover-inner .ant-select-single:not(.ant-select-customize-input) .ant-select-selector input:focus,
.editor-list .ant-select input:focus,
.editor-list-popover .ant-popover-inner .ant-select input:focus,
.editor-list .ant-select-selection input:focus,
.editor-list-popover .ant-popover-inner .ant-select-selection input:focus,
.editor-list .ant-select-auto-complete.ant-select input:focus,
.editor-list-popover .ant-popover-inner .ant-select-auto-complete.ant-select input:focus,
.editor-list .ant-select-single:not(.ant-select-customize-input) .ant-select-selector input-focused,
.editor-list-popover .ant-popover-inner .ant-select-single:not(.ant-select-customize-input) .ant-select-selector input-focused,
.editor-list .ant-select input-focused,
.editor-list-popover .ant-popover-inner .ant-select input-focused,
.editor-list .ant-select-selection input-focused,
.editor-list-popover .ant-popover-inner .ant-select-selection input-focused,
.editor-list .ant-select-auto-complete.ant-select input-focused,
.editor-list-popover .ant-popover-inner .ant-select-auto-complete.ant-select input-focused {
  border-color: rgba(255, 255, 255, 0.9);
  box-shadow: none;
}
.editor-list .ant-select-single .ant-select-selector,
.editor-list-popover .ant-popover-inner .ant-select-single .ant-select-selector {
  padding: 0;
}
.editor-list .ant-select-single .ant-select-selector .ant-select-selection-search,
.editor-list-popover .ant-popover-inner .ant-select-single .ant-select-selector .ant-select-selection-search {
  left: 0;
  right: 0;
}
.editor-list .ant-select-focused .ant-select-selection,
.editor-list-popover .ant-popover-inner .ant-select-focused .ant-select-selection,
.editor-list .ant-select-open .ant-select-selection,
.editor-list-popover .ant-popover-inner .ant-select-open .ant-select-selection {
  border-color: rgba(255, 255, 255, 0.9);
  box-shadow: 0 0 4px rgba(255, 255, 255, 0.9);
}
.editor-list .ant-select-selection .ant-select-selection__placeholder,
.editor-list-popover .ant-popover-inner .ant-select-selection .ant-select-selection__placeholder {
  color: rgba(255, 255, 255, 0.15);
  width: 100%;
  margin-left: 0;
  margin-right: 0;
  padding: 1px 7px;
}
.editor-list .ant-input-number-handler-wrap,
.editor-list-popover .ant-popover-inner .ant-input-number-handler-wrap {
  background: transparent;
}
.editor-list .ant-input-number-handler-wrap .ant-input-number-handler .ant-input-number-handler-up-inner,
.editor-list-popover .ant-popover-inner .ant-input-number-handler-wrap .ant-input-number-handler .ant-input-number-handler-up-inner,
.editor-list .ant-input-number-handler-wrap .ant-input-number-handler .ant-input-number-handler-down-inner,
.editor-list-popover .ant-popover-inner .ant-input-number-handler-wrap .ant-input-number-handler .ant-input-number-handler-down-inner {
  color: rgba(255, 255, 255, 0.55);
}
.editor-list .ant-input-number-handler-wrap .ant-input-number-handler .ant-input-number-handler-up-inner:hover,
.editor-list-popover .ant-popover-inner .ant-input-number-handler-wrap .ant-input-number-handler .ant-input-number-handler-up-inner:hover,
.editor-list .ant-input-number-handler-wrap .ant-input-number-handler .ant-input-number-handler-down-inner:hover,
.editor-list-popover .ant-popover-inner .ant-input-number-handler-wrap .ant-input-number-handler .ant-input-number-handler-down-inner:hover {
  color: rgba(255, 255, 255, 0.55);
}
.editor-list .ant-radio-button-wrapper,
.editor-list-popover .ant-popover-inner .ant-radio-button-wrapper {
  color: rgba(255, 255, 255, 0.55);
  background: transparent;
  border-color: rgba(255, 255, 255, 0.55);
  width: 42px;
  text-align: center;
}
.editor-list .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):first-child,
.editor-list-popover .ant-popover-inner .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):first-child,
.editor-list .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover,
.editor-list-popover .ant-popover-inner .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover,
.editor-list .ant-radio-button-wrapper-checked,
.editor-list-popover .ant-popover-inner .ant-radio-button-wrapper-checked {
  color: rgba(255, 255, 255, 0.9);
  background: #121212;
  border-color: rgba(255, 255, 255, 0.9);
  box-shadow: -1px 0 0 0 rgba(255, 255, 255, 0.9);
}
.editor-list .ant-radio-button-wrapper:not(:first-child)::before,
.editor-list-popover .ant-popover-inner .ant-radio-button-wrapper:not(:first-child)::before {
  background-color: rgba(255, 255, 255, 0.3);
}
.editor-list .ant-radio-button-auto-width,
.editor-list-popover .ant-popover-inner .ant-radio-button-auto-width {
  width: auto;
}
.editor-list .select-input,
.editor-list-popover .ant-popover-inner .select-input {
  position: relative;
}
.editor-list .select-input .anticon,
.editor-list-popover .ant-popover-inner .select-input .anticon {
  position: absolute;
  right: 4px;
  line-height: 22px;
  cursor: pointer;
  font-size: 12px;
  transform: scale(0.75) rotate(0deg);
  z-index: 2;
}
.editor-list .ant-switch-checked,
.editor-list-popover .ant-popover-inner .ant-switch-checked {
  background-color: rgba(255, 255, 255, 0.55);
}
.editor-list .add-button,
.editor-list-popover .ant-popover-inner .add-button {
  display: block;
  width: 100%;
  height: 36px;
  line-height: 36px;
  background: #121212;
  border-radius: 4px;
  text-align: center;
  color: rgba(255, 255, 255, 0.55);
  font-size: 24px;
  box-shadow: 0 0 0 rgba(0, 0, 0, 0);
  transition: transform 0.3s cubic-bezier(0.215, 0.61, 0.355, 1), box-shadow 0.3s cubic-bezier(0.215, 0.61, 0.355, 1), color 0.3s cubic-bezier(0.215, 0.61, 0.355, 1), background 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
}
.editor-list .add-button:hover,
.editor-list-popover .ant-popover-inner .add-button:hover {
  transform: translateY(-2px) scale(1.01);
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.35);
  color: rgba(255, 255, 255, 0.9);
  background: #121212;
}
.editor-list .add-button:active,
.editor-list-popover .ant-popover-inner .add-button:active {
  transform: translateY(-1px) scale(1.005);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.35);
}
.editor-list .icon-button,
.editor-list-popover .ant-popover-inner .icon-button {
  cursor: pointer;
  display: block;
}
.editor-list .editor-transition-tween .ant-row,
.editor-list-popover .ant-popover-inner .editor-transition-tween .ant-row {
  overflow: hidden;
}
.editor-list .ant-tag-checkable,
.editor-list-popover .ant-popover-inner .ant-tag-checkable {
  color: rgba(255, 255, 255, 0.55);
  margin-bottom: 8px;
  border: 1px solid rgba(255, 255, 255, 0.55);
}
.editor-list .ant-tag-checkable:hover,
.editor-list-popover .ant-popover-inner .ant-tag-checkable:hover {
  color: rgba(255, 255, 255, 0.55);
}
.editor-list .ant-tag-checkable:active,
.editor-list-popover .ant-popover-inner .ant-tag-checkable:active,
.editor-list .ant-tag-checkable.ant-tag-checkable-checked,
.editor-list-popover .ant-popover-inner .ant-tag-checkable.ant-tag-checkable-checked {
  border-color: rgba(255, 255, 255, 0.9);
  color: rgba(255, 255, 255, 0.9);
  background: #121212;
}
.editor-list .ant-popover-inner-content,
.editor-list-popover .ant-popover-inner .ant-popover-inner-content {
  padding: 0;
  color: rgba(255, 255, 255, 0.55);
}
.editor-list .sketch-picker,
.editor-list-popover .ant-popover-inner .sketch-picker {
  box-shadow: none !important;
  color: #262626;
}
.editor-list .sketch-picker input,
.editor-list-popover .ant-popover-inner .sketch-picker input {
  color: #262626;
}
.editor-list .ant-row,
.editor-list-popover .ant-popover-inner .ant-row {
  margin-bottom: 8px;
}
.editor-list .ant-checkbox,
.editor-list-popover .ant-popover-inner .ant-checkbox {
  color: rgba(255, 255, 255, 0.55);
}
.editor-list .ant-checkbox + span,
.editor-list-popover .ant-popover-inner .ant-checkbox + span {
  color: rgba(255, 255, 255, 0.55);
}
.editor-list .ant-checkbox-wrapper:hover .ant-checkbox-inner,
.editor-list-popover .ant-popover-inner .ant-checkbox-wrapper:hover .ant-checkbox-inner {
  border-color: rgba(255, 255, 255, 0.55);
}
.editor-list .ant-checkbox-checked .ant-checkbox-inner,
.editor-list-popover .ant-popover-inner .ant-checkbox-checked .ant-checkbox-inner {
  border-color: rgba(255, 255, 255, 0.55);
  background-color: #121212;
}
.editor-list-popover .ant-popover-content .ant-popover-inner {
  background-color: #121212;
  box-shadow: 0 0 16px rgba(18, 18, 18, 0.7);
  position: relative;
  z-index: 1;
}
.editor-list-popover .ant-popover-content .ant-popover-arrow {
  border-right-color: #121212;
  border-bottom-color: #121212;
  box-shadow: 0 0 16px rgba(18, 18, 18, 0.7);
}
